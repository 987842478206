import React from 'react';
import { Checkbox, Row, Col, Icon, Divider, Select, Button, Input, DatePicker } from 'antd';
import _ from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppContext } from '../../../../includes/indexProvider';

const { Option } = Select;

const ListItemFilter = (props) => {
  const { onChange, searchGroup, listDocumnetType, listCategory, listTopic} = props;
  const intl = useIntl();
  const app = useAppContext();
  const langValue = app.state.langValue;
  console.log('langValue', langValue)
  const dateFormat = 'DD/MM/YYYY';

  console.log('tempchange', listDocumnetType);

  return (
    <div>
      <div style={
        { 
          // overflowY: 'auto', 
          // height: `300px`, 
          padding: '5px 0px 0px 24px' }
        }
      >
          <div style={{ padding: '16px 16px 8px 0px' }}>
            <div style={{ marginBottom: '16px' }}>
              <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
                <FormattedMessage id="reportAssetExpenseLabelTicketNo" defaultMessage="Ticket No." />
              </div>
              <div>
                <Input
                  allowClear={true}
                  onChange={(e) => onChange(e.target.value, 'ticketNo')}
                  value={_.get(searchGroup, 'ticketNo')}
                  placeholder={intl.formatMessage({
                    id: 'reportAssetExpensePHRefTicketNo',
                    defaultMessage: 'Enter Ticket No.',
                  })}
                />
              </div>
            </div>

            <div style={{ marginBottom: '16px' }}>
              <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
                <FormattedMessage id="reportAssetExpenseLabelTopic" defaultMessage="Topic" />
              </div>
              <Select
                allowClear
                showSearch
                onChange={(value) => onChange(value, 'topic')}
                filterOption={false}
                value={_.get(searchGroup, 'topic')}
                placeholder={intl.formatMessage({ id: 'reportAssetExpensePHRefTopic', defaultMessage: 'Select Topic' })}
                style={{ width: '100%' }}
              >
                {_.map(listTopic, (item) => (
                <Option value={item.topicId}>
                  {item.topicName}
                </Option>
              ))}
              </Select>
            </div>

            <div style={{ marginBottom: '16px' }}>
              <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
                <FormattedMessage id="reportAssetExpenseLabelDocumentType" defaultMessage="Document Type" />
              </div>
              <Select
                allowClear
                showSearch
                onChange={(value) => onChange(value, 'documentType')}
                filterOption={false}
                value={_.get(searchGroup, 'documentType')}
                placeholder={intl.formatMessage({ id: 'reportAssetExpensePHRefDocumentType', defaultMessage: 'Select Document Type' })}
                style={{ width: '100%' }}
              >
                {_.map(listDocumnetType, (item) => (
                <Option value={item.code}>
                  {langValue === 'TH' ? _.get(item, 'documentTypeName.TH') : _.get(item, 'documentTypeName.EN')}
                </Option>
              ))}
              </Select>
            </div>

            <div style={{ marginBottom: '16px' }}>
              <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
                <FormattedMessage id="reportAssetExpenseLabelDocumentNo" defaultMessage="Document No." />
              </div>
              <div>
                <Input
                  allowClear={true}
                  onChange={(e) => onChange(e.target.value, 'documentNo')}
                  value={_.get(searchGroup, 'documentNo')}
                  placeholder={intl.formatMessage({
                    id: 'reportAssetExpensePHRefDocumentNo',
                    defaultMessage: 'Enter Document No.',
                  })}
                />
              </div>
            </div>

            <div style={{ marginBottom: '16px' }}>
              <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
                <FormattedMessage id="reportAssetExpenseLabelItemCode" defaultMessage="Item Code" />
              </div>
              <div>
                <Input
                  allowClear={true}
                  onChange={(e) => onChange(e.target.value, 'itemCode')}
                  value={_.get(searchGroup, 'itemCode')}
                  placeholder={intl.formatMessage({
                    id: 'reportAssetExpensePHRefItemCode',
                    defaultMessage: 'Enter Item Code',
                  })}
                />
              </div>
            </div>

            <div style={{ marginBottom: '16px' }}>
              <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
                <FormattedMessage id="reportAssetExpenseLabelItem" defaultMessage="Item" />
              </div>
              <div>
                <Input
                  allowClear={true}
                  onChange={(e) => onChange(e.target.value, 'item')}
                  value={_.get(searchGroup, 'item')}
                  placeholder={intl.formatMessage({
                    id: 'reportAssetExpensePHRefItem',
                    defaultMessage: 'Enter Item',
                  })}
                />
              </div>
            </div>

            <div style={{ marginBottom: '16px' }}>
              <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
                <FormattedMessage id="reportAssetExpenseLabelItemCategory" defaultMessage="Item Category" />
              </div>
              <Select
                allowClear
                showSearch
                onChange={(value) => onChange(value, 'itemCategory')}
                filterOption={false}
                value={_.get(searchGroup, 'itemCategory')}
                placeholder={intl.formatMessage({ id: 'reportAssetExpensePHRefCategory', defaultMessage: 'Select Item Category' })}
                style={{ width: '100%' }}
              >
                {_.map(listCategory, (item) => (
                <Option value={item.code}>
                  {langValue === 'TH' ? _.get(item, 'itemCategoryName.TH') : _.get(item, 'itemCategoryName.EN')}
                </Option>
              ))}
            
              </Select>
            </div>

            {/* <div style={{ marginBottom: '16px' }}>
              <div className="listItemFilterContentHeadText" style={{ marginBottom: '8px' }}>
                <FormattedMessage id="reportAssetExpenseLabelRemark" defaultMessage="Remark" />
              </div>
              <div>
                <Input
                  allowClear={true}
                  onChange={(e) => onChange(e.target.value, 'remark')}
                  value={_.get(searchGroup, 'remark')}
                  placeholder={intl.formatMessage({
                    id: 'reportAssetExpensePHRefRemark',
                    defaultMessage: 'Enter Remark',
                  })}
                />
              </div>
            </div> */}
          </div>
      </div>
    </div>
  );
};

export default ListItemFilter;
