import React, { createContext, useEffect, useState } from 'react';
import {
  createSaleOrder,
  getIntialListSaleOrder,
  getMemberIssueBy,
  getSaleOrderById,
  getSaleOrderList,
  getSaleOrderReportById,
  updateSaleOrder,
  updateStatusSaleOrder,
  uploadAttachment,
  getSaleOrderStatus,
  getAttachmentSO,
  deleteAttachmentSaleOrder,
  getSummaryApproved,
  exportExcel,
} from '../../controllers/sale-order/sale-order-api-new';
import _, { map } from 'lodash';
import moment from 'moment';
import { getMemberAsset } from '../../controllers/asset-resource/asset-controller';
import { errorNotification, successNotification } from '../../components/v2/notification';
import { GetSignaturePrint, GetSignaturePrintIssu, getQuotationReportById } from '../../controllers/quotation/quotation-api-new';
import { useIntl } from 'react-intl';
import { getCustomerLoadMore } from '../../controllers/getCustomer/get-customer-new';
import { getStockListById, getVehicleList } from '../../controllers/po/po';
import {
  createInvoice,
  exportFileInvoice,
  getDetailInvoice,
  getInvoiceList,
  updatestatusInvoice,
  uploadFileInvoice,
} from '../../controllers/invoice-new/invoice-api';
import { getSaleOderDetail } from '../../controllers/account-receive';
import { messageLabel } from '../../components/message-component';

const InvoiceContext = createContext();

const InvoiceProvider = ({ children }) => {
  const intl = useIntl();
  const [isOpenQuotation, seIsOpenQuotation] = useState(false);
  const [isFetch, setIsFetch] = useState(false);
  const [record, setRecord] = useState();
  const [recordReport, setRecordReport] = useState();
  const [signatureUser, setSignatureUser] = useState();
  const [sizeRecordReport, setSizeRecordReport] = useState();
  const [signatureIssu, setSignatureIssu] = useState();

  const [openUpload, setOpenUpload] = useState(false);
  const [attachmentData, setAttachmentData] = useState([]);
  const [typeAttachment, setTypeAttachment] = useState('add');
  const [defaultAttachment, setDefaultAttachment] = useState();
  const [defaultSaleOrder, setDefaultSaleOrder] = useState();

  const [tabKey, setTabKey] = useState('waiting');
  const [initialMemberList, setInitialMemberList] = useState([]);
  const [initialTaxList, setInitialTaxList] = useState([]);
  const [initialReasonList, setInitialReasonList] = useState([]);
  const [initialCampaignList, setInitialCampaignList] = useState([]);

  const [statusList, setStatusList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);

  const [visibleApprove, setVisibleApprove] = useState(false);
  const [visibleReject, setVisibleReject] = useState(false);
  const [visibleCancel, setVisibleCancel] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [visibleWaiting, setVisibleWaiting] = useState(false);

  const [customerPage, setCustomerPage] = useState(10);
  const [customerLoading, setCustomerLoading] = useState(false);
  const [customerTrigger, setCustomerTrigger] = useState(false);

  //------------------------------

  const [pageTable, setPageTable] = useState(1);
  const [sizeTable, setSizeTable] = useState(10);
  const [warehouseList, setWarehouseList] = useState([]);
  const [saleOrderQTY, setSaleOrderQTY] = useState([]);
  const [recordList, setRecordList] = useState([]);
  const [totalWarehouseList, setTotalWarehouseList] = useState([]);
  const [visibleAddINV, setVisibleAddINV] = useState(false);

  //------------------------------

  const [reportDataWaiting, setReportDataWaiting] = useState({
    quotationList: [],
    quotationTotal: 0,
    extraSort: undefined,
    fieldSort: '',
    orderSort: undefined,
    orderBy: undefined,
    paginationPage: 1,
    paginationSize: 10,
    loading: false,
    invoiceNo: '',
    saleOrderNo: '',
    customerId: undefined,
    statusCode: [],
    rangePickerDate: [moment().startOf('month'), moment().endOf('month')],
  });

  const [reportDataApproved, setReportDataApproved] = useState({
    quotationList: [],
    quotationTotal: 0,
    extraSort: undefined,
    fieldSort: '',
    orderSort: undefined,
    paginationPage: 1,
    paginationSize: 10,
    loading: false,
    quotationNo: '',
    saleOrderNo: '',
    customerName: '',
    rangePickerDate: [moment().startOf('month'), moment().endOf('month')],
    summary: {},
  });

  const [reportDataRejected, setReportDataRejected] = useState({
    quotationList: [],
    quotationTotal: 0,
    extraSort: undefined,
    fieldSort: '',
    orderSort: undefined,
    paginationPage: 1,
    paginationSize: 10,
    loading: false,
    invoiceNo: '',
    saleOrderNo: '',
    paymentTermNo: '',
    rangePickerDate: [moment().startOf('month'), moment().endOf('month')],
  });

  const [reportDataCanceled, setReportDataCanceled] = useState({
    quotationList: [],
    quotationTotal: 0,
    extraSort: undefined,
    fieldSort: '',
    orderSort: undefined,
    paginationPage: 1,
    paginationSize: 10,
    loading: false,
    quotationNo: '',
    saleOrderNo: '',
    customerId: '',
    rangePickerDate: [moment().startOf('month'), moment().endOf('month')],
  });

  const [selectCustomer, setSelectCustomer] = useState();

  useEffect(() => {
    console.log('reportDataWaiting', reportDataWaiting);
    setReportDataWaiting((prev) => ({ ...prev, loading: true }));
    const getApi = async () => {
      const payload = {
        pageNumber: _.get(reportDataWaiting, 'paginationPage'),
        limit: _.get(reportDataWaiting, 'paginationSize'),
        orderBy: _.get(reportDataWaiting, 'fieldSort'),
        orderType: _.get(reportDataWaiting, 'orderSort'),
        invoiceNo: _.get(reportDataWaiting, 'invoiceNo'),
        paymentTermNo: _.get(reportDataWaiting, 'paymentTermNo'),
        saleOrderNo: _.get(reportDataWaiting, 'saleOrderNo'),
        customerId: _.get(reportDataWaiting, 'customerId') ? [_.get(reportDataWaiting, 'customerId')] : [],
        statusCode: _.size(_.get(reportDataWaiting, 'statusCode')) > 0 ? _.get(reportDataWaiting, 'statusCode') : undefined,
        startDate: _.get(reportDataWaiting, 'rangePickerDate[0]')
          ? moment(_.get(reportDataWaiting, 'rangePickerDate[0]')).format('YYYY-MM-DD')
          : '',
        endDate: _.get(reportDataWaiting, 'rangePickerDate[1]')
          ? moment(_.get(reportDataWaiting, 'rangePickerDate[1]')).format('YYYY-MM-DD')
          : '',
        type: 'invoice',
      };
      const response = await getInvoiceList(payload);
      if (_.get(response, 'status.code') === 200) {
        setReportDataWaiting((prev) => ({
          ...prev,
          quotationList: _.get(response, 'data.invoiceList'),
          // quotationList: _.get(response, 'data.saleOrderARList'),
          quotationTotal: _.get(response, 'data.totalItem'),
        }));
        setStatusList(_.get(response, 'data.statusList'));
      }
    };
    setReportDataWaiting((prev) => ({ ...prev, loading: false }));
    if (tabKey === 'waiting') {
      getApi();
    }
  }, [
    isFetch,
    tabKey,
    _.get(reportDataWaiting, 'rangePickerDate[0]'),
    _.get(reportDataWaiting, 'rangePickerDate[1]'),
    _.get(reportDataWaiting, 'paginationPage'),
    _.get(reportDataWaiting, 'paginationSize'),
    _.get(reportDataWaiting, 'fieldSort'),
    _.get(reportDataWaiting, 'orderSort'),
  ]);

  useEffect(() => {
    const getApi = async () => {
      let payload = {};
      const result = await getMemberIssueBy(payload);
      setInitialMemberList(result || []);
    };
    getApi();
  }, []);

  // useEffect(() => {
  //   setReportDataPending((prev) => ({ ...prev, loading: true }));
  //   const getApi = async () => {
  //     const payload = {
  //       tabCode: 'pending',
  //       issueDateStart: _.get(reportDataPending, 'rangePickerDate[0]')
  //         ? moment(_.get(reportDataPending, 'rangePickerDate[0]')).format('YYYY-MM-DD')
  //         : undefined,
  //       issueDateEnd: _.get(reportDataPending, 'rangePickerDate[1]')
  //         ? moment(_.get(reportDataPending, 'rangePickerDate[1]')).format('YYYY-MM-DD')
  //         : undefined,
  //       pageNumber: _.get(reportDataPending, 'paginationPage'),
  //       limit: _.get(reportDataPending, 'paginationSize'),
  //       orderBy: _.get(reportDataPending, 'fieldSort'),
  //       orderType: _.get(reportDataPending, 'orderSort'),
  //       paymentTermNo: _.get(reportDataPending, 'paymentTermNo'),
  //       saleOrderNo: _.get(reportDataPending, 'saleOrderNo'),
  //       customerId: _.get(reportDataPending, 'customerName') ? [_.get(reportDataPending, 'customerName')] : [],
  //       invoiceNo: '',
  //       statusCode: _.get(reportDataPending, 'statusCode'),
  //     };
  //     const response = await getSaleOrderPendingList(payload);

  //     setReportDataPending((prev) => ({
  //       ...prev,
  //       quotationList: _.get(response, 'data.saleOrderARList'),
  //       quotationTotal: _.get(response, 'data.totalItem'),
  //     }));
  //   };
  //   setReportDataPending((prev) => ({ ...prev, loading: false }));
  //   if (tabKey === 'pending') {
  //     getApi();
  //   }
  // }, [
  //   _.get(reportDataPending, 'rangePickerDate[0]'),
  //   _.get(reportDataPending, 'rangePickerDate[1]'),
  //   _.get(reportDataPending, 'paginationPage'),
  //   _.get(reportDataPending, 'paginationSize'),
  //   _.get(reportDataPending, 'fieldSort'),
  //   _.get(reportDataPending, 'orderSort'),
  //   _.get(reportDataPending, 'statusCode'),
  //   _.get(reportDataPending, 'saleOrderNo'),
  //   _.get(reportDataPending, 'customerName'),
  // ]);

  useEffect(() => {
    async function getUser() {
      try {
        const pageStart = 1;
        const pageEnd = customerPage;

        const data = await getCustomerLoadMore(pageStart, pageEnd, _.get(reportDataWaiting, 'customerId'));
        console.log('CustomerVoucher', data);
        setCustomerList(_.get(data, 'customers'));
        setCustomerLoading(false);
      } catch (error) {}
    }

    getUser();
    console.log('INCS', _.get(reportDataWaiting, 'customerId'));
  }, [customerPage, _.get(reportDataWaiting, 'customerId')]);

  useEffect(() => {
    const getApi = async () => {
      const resultTax = await getIntialListSaleOrder('tax');
      const resultReason = await getIntialListSaleOrder('reason');
      const resultCampaign = await getIntialListSaleOrder('campaign');
      setInitialTaxList(resultTax || []);
      setInitialReasonList(resultReason || []);
      setInitialCampaignList(resultCampaign || []);
    };
    getApi();
  }, []);

  console.log('initialReasonList', initialReasonList);
  const handleOpenModalQuotation = async (row, code) => {
    if (row) {
      try {
        const response = await getDetailInvoice(row.invoiceId);
        const vehicleList = await getVehicleList({ searchVal: '', indexStart: 0, indexEnd: 1000 });
        setVehicleList(_.get(vehicleList, 'data.assetList'));
        console.log('getSaleOrderById', code, response);
        if (_.get(response, 'status.code')) {
          handleOpenModalQuotationPendingDraft(_.get(response, 'data'));
          setRecord({ ..._.get(response, 'data'), code: code, attanchment: _.get(response, 'data.attanchment') });
          handleCountSize(_.get(response, 'data.itemList'), _.get(response, 'data'));
          seIsOpenQuotation(true);
        }
      } catch (error) {}
    } else {
      const vehicleList = await getVehicleList({ searchVal: '', indexStart: 0, indexEnd: 1000 });
      setVehicleList(_.get(vehicleList, 'data.assetList'));
      seIsOpenQuotation(true);
      setRecord();
    }
  };

  const refreshModalSaleOrder = async (invoiceId, code) => {
    try {
      // const response = await getSaleOrderById(saleOrderId);
      const response = await getDetailInvoice(invoiceId);
      // const responseAttachment = await getAttachmentSO(saleOrderId);
      // const responsereport = await getSaleOrderReportById(saleOrderId);
      // const responseSignature = await GetSignaturePrint();
      // const responseSignatureIssu = await GetSignaturePrintIssu(_.get(response, 'data[0].issueBy'));
      const vehicleList = await getVehicleList({ searchVal: '', indexStart: 0, indexEnd: 1000 });
      setVehicleList(_.get(vehicleList, 'data.assetList'));
      // const responsereport = await getQuotationReportById(row.saleOrderId);
      console.log('getSaleOrderById', code, response);
      console.log('responseAttachment');
      if (_.get(response, 'status.code')) {
        setRecord({ ..._.get(response, 'data'), code: code });
        handleCountSize(_.get(response, 'data.itemList'), _.get(response, 'data'));
      }
    } catch (error) {}
  };

  const handleCountSize = (data, alldata) => {
    let count = 0;
    let reportmap = [];
    console.log('recordReportDatadefult', data, alldata);

    let tempgdata = _.chain(data)
      .groupBy('credit')
      .map((value, key) => ({ credit: key, detail: value }))
      .value();
    console.log('recordReportData', tempgdata);
    const day = _.split(_.get(alldata, 'issueDate'), '-');
    const years = parseInt(day[0]) + 543;

    _.map(tempgdata, (item) => {
      let total = _.sumBy(_.get(item, 'detail'), 'totalNum');
      console.log('credisDays', day);
      const newday = parseInt(day[2]) + parseInt(item.credit);
      const newdate = _.toString(newday + '/' + parseInt(day[1]) + '/' + years);
      reportmap.push(
        { credit: item.credit, role: 'head', total: total, creditday: newdate }
        // item
      );
      _.map(_.get(item, 'detail'), (detail) => {
        count = count + 1;
        reportmap.push({ ...detail, index: count });
      });
    });

    setRecordReport(reportmap);
    console.log('reportmap', reportmap);
  };

  // const handleCountSize = (data) => {
  //   let count = 0;
  //   let reportmap = [];

  //   _.map(data, (item) => {
  //     let total = _.sumBy(_.get(item, 'detail'), 'total');
  //     // reportmap.push(
  //     //   { credit: item.credit, role: 'head',total: total }
  //     // );
  //     console.log('detailmap', _.sumBy(_.get(item, 'detail'), 'total'));
  //     _.map(_.get(item, 'detail'), (detail) => {
  //       count = count + 1;
  //       reportmap.push({ ...detail, index: count });
  //     });
  //   });
  //   // setRecordReport((item) => [...reportmap,reportmap]);
  //   setRecordReport(reportmap);
  //   console.log('reportmap', reportmap, data);
  //   setSizeRecordReport(_.size(reportmap));
  // };

  const handleOpenModalUpload = (row) => {
    if (row) {
      setOpenUpload(true);
      setDefaultAttachment(row);
      setTypeAttachment('edit');
    } else {
      setOpenUpload(true);
      setDefaultAttachment();
      setTypeAttachment('add');
    }
  };

  const handleSaveQuotation = async (payload, resetFields) => {
    console.log('handleSaveQuotation', payload, attachmentData);
    try {
      let response;

      response = await createInvoice(payload);

      console.log('createSaleOrder', response);
      if (_.get(response, 'status.code') === 200) {
        successNotification(_.get(response, 'status.message'));
        resetFields();
      } else {
        errorNotification(_.get(response, 'status.message'));
      }
      setIsFetch((prev) => !prev);
      seIsOpenQuotation(false);
    } catch (error) {
      errorNotification(_.get(error, 'response.status.message'));
      return;
    }
  };

  const handleCancelQuotation = () => {
    seIsOpenQuotation(false);
    setRecord();
    setDefaultSaleOrder();
  };

  const handleOpenApprove = (value) => {
    setVisibleApprove(true);
  };

  const handleSaveApprove = async (value) => {
    try {
      const response = await updateStatusSaleOrder(value);
      console.log('updateStatusSaleOrder', response);

      if (response) {
        const response2 = await getSaleOrderById(response.saleOrderId);
        const responseAttachment = await getAttachmentSO(response.saleOrderId);
        console.log('getSaleOrderById', value.key, response2, response);
        if (_.get(response2, 'status.code')) {
          setRecord({ ..._.get(response2, 'data[0]'), code: value.code, attachment: responseAttachment });
          successNotification('Success');
        }
      }
      // if (_.get(response, 'status.code') === 200) {
      //   successNotification(_.get(response, 'status.message'));
      // }
      setIsFetch((prev) => !prev);
      setVisibleApprove(false);
    } catch (error) {
      errorNotification(_.get(error, 'response.status.message'));
      return;
    }
  };

  const handleCancelApprove = () => {
    setVisibleApprove(false);
  };

  const handleOpenReject = (value) => {
    setVisibleReject(true);
  };

  const handleSaveReject = async (value) => {
    try {
      const response = await updateStatusSaleOrder(value);
      console.log('updateStatusSaleOrder', response);

      if (response) {
        const response2 = await getSaleOrderById(response.saleOrderId);
        console.log('getSaleOrderById', value.key, response2, response);
        if (_.get(response2, 'status.code')) {
          setRecord({ ..._.get(response2, 'data[0]'), code: value.code });
          successNotification('Success');
        }
      }
      // if (_.get(response, 'status.code') === 200) {
      //   successNotification(_.get(response, 'status.message'));
      // }
      setIsFetch((prev) => !prev);
      setVisibleReject(false);
    } catch (error) {
      errorNotification(_.get(error, 'response.status.message'));
      return;
    }
  };

  const handleCancelReject = (value) => {
    setVisibleReject(false);
  };

  const handleOpenCancel = (value) => {
    setVisibleCancel(true);
  };

  const handleSaveCancel = async (value) => {
    console.log('handleSaveCancel ++', value);
    try {
      const response = await updatestatusInvoice(value);
      console.log('updateStatusSaleOrder', response);

      if (_.get(response, 'status.code') === 200) {
        const response2 = await getDetailInvoice(value.invoiceId);
        console.log('getSaleOrderById', value.key, response2, response);
        if (_.get(response2, 'status.code')) {
          setRecord({ ..._.get(response2, 'data'), code: value.code });
          successNotification('Success');
        }
      } else {
        errorNotification(_.get(response, 'status.message'));
      }
      // if (_.get(response, 'status.code') === 200) {
      //   successNotification(_.get(response, 'status.message'));
      // }
      setIsFetch((prev) => !prev);
      setVisibleCancel(false);
    } catch (error) {
      errorNotification(_.get(error, 'response.status.message'));
      return;
    }
  };

  const handleCancelCancelQT = (value) => {
    setVisibleCancel(false);
  };

  const handleOpenDelete = (value) => {
    setVisibleDelete(true);
  };

  const handleSaveDelete = async (value) => {
    try {
      const response = await updateStatusSaleOrder(value);
      console.log('updateStatusSaleOrder', response);

      if (response && _.get(value, 'status') !== 'deleted') {
        const response2 = await getSaleOrderById(response.saleOrderId);
        console.log('getSaleOrderById', value.key, response2, response);
        if (_.get(response2, 'status.code')) {
          setRecord({ ..._.get(response2, 'data[0]'), code: value.code });
          successNotification('Success');
        }
      }
      // if (_.get(response, 'status.code') === 200) {
      //   successNotification(_.get(response, 'status.message'));
      // }
      setIsFetch((prev) => !prev);
      setVisibleDelete(false);
      seIsOpenQuotation(false);
    } catch (error) {
      errorNotification(_.get(error, 'response.status.message'));
      return;
    }
  };

  const handleCancelDelete = (value) => {
    setVisibleDelete(false);
  };

  const handleOpenWaiting = (value) => {
    setVisibleWaiting(true);
  };

  const handleSaveWaiting = async (value) => {
    try {
      const response = await updateStatusSaleOrder(value);
      console.log('updateStatusSaleOrder', response);

      if (response) {
        const response2 = await getSaleOrderById(response.saleOrderId);
        console.log('getSaleOrderById', value.key, response2, response);
        if (_.get(response2, 'status.code')) {
          setRecord({ ..._.get(response2, 'data[0]'), code: value.code });
          successNotification('Success');
        }
      }
      // if (_.get(response, 'status.code') === 200) {
      //   successNotification(_.get(response, 'status.message'));
      // }
      setIsFetch((prev) => !prev);
      setVisibleWaiting(false);
    } catch (error) {
      errorNotification(_.get(error, 'response.status.message'));
      return;
    }
  };

  const handleCancelWaitng = (value) => {
    setVisibleWaiting(false);
  };

  const handleDeleteAttAchment = async (id) => {
    try {
      let response;
      response = await deleteAttachmentSaleOrder(id);
    } catch (error) {
      errorNotification(_.get(error, 'response.status.message'));
      return;
    }
  };

  const handleCancelWaiting = (value) => {
    setVisibleWaiting(false);
  };

  const handleExport = async () => {
    console.log('handleExport', reportDataApproved, tabKey, reportDataWaiting);
    try {
      const tabValue =
        tabKey === 'waiting'
          ? reportDataWaiting
          : tabKey === 'approved'
          ? reportDataApproved
          : tabKey === 'rejected'
          ? reportDataRejected
          : tabKey === 'canceled'
          ? reportDataCanceled
          : null;

      const payload = {
        pageNumber: 1,
        limit: _.get(reportDataWaiting, 'quotationTotal'),
        orderBy: _.get(reportDataWaiting, 'fieldSort'),
        orderType: _.get(reportDataWaiting, 'orderSort'),
        invoiceNo: _.get(reportDataWaiting, 'invoiceNo'),
        paymentTermNo: _.get(reportDataWaiting, 'paymentTermNo'),
        saleOrderNo: _.get(reportDataWaiting, 'saleOrderNo'),
        customerId: _.get(reportDataWaiting, 'customerId') ? [_.get(reportDataWaiting, 'customerId')] : [],
        statusCode: _.size(_.get(reportDataWaiting, 'statusCode')) > 0 ? _.get(reportDataWaiting, 'statusCode') : undefined,

        isCreateBilling: false,
        viewFormat: [
          {
            colName: intl.formatMessage({ id: 'invoiceExportINVNo', defaultMessage: 'INV No.' }),
            sequence: 1,
            colCode: 'invoiceNo',
          },
          {
            colName: intl.formatMessage({ id: 'invoiceExportSoNo', defaultMessage: 'SO No.' }),
            sequence: 2,
            colCode: 'saleOrderNo',
          },

          {
            colName: intl.formatMessage({ id: 'invoiceExportCustomerName', defaultMessage: 'Customer Name' }),
            sequence: 3,
            colCode: 'customerName',
          },
          {
            colName: intl.formatMessage({ id: 'invoiceExportBLNo', defaultMessage: 'BL No.' }),
            sequence: 4,
            colCode: 'billingNo',
          },
          {
            colName: intl.formatMessage({ id: 'invoiceExportStatus', defaultMessage: 'Status' }),
            sequence: 5,
            colCode: 'statusTxt',
          },
          {
            colName: intl.formatMessage({ id: 'invoiceExportTotal', defaultMessage: 'Total' }),
            sequence: 6,
            colCode: 'totalTxt',
          },
          {
            colName: intl.formatMessage({ id: 'invoiceExportIssueby', defaultMessage: 'Issue by' }),
            sequence: 7,
            colCode: 'issueBy',
          },
          {
            colName: intl.formatMessage({ id: 'invoiceExportTeam', defaultMessage: 'Team' }),
            sequence: 8,
            colCode: 'team',
          },
          {
            colName: intl.formatMessage({ id: 'invoiceExportIssueDate', defaultMessage: 'Issued Date' }),
            sequence: 9,
            colCode: 'issueDateTxt',
          },
        ],
      };

      const response = await exportFileInvoice(payload);

      if (response.data.status.code === 200) {
        window.open(response.data.data.url);
        // successNotification(response.data.getUrl.message);
      } else {
        // errorNotification(response.data.getUrl.message);
      }
    } catch (error) {}
  };

  const handleCustomerPopupScroll = (e) => {
    let target = e.target;
    if (!customerLoading && target.scrollTop + target.offsetHeight >= target.scrollHeight - 50) {
      setCustomerPage(customerPage + 10);
      setCustomerLoading(true);
    }
  };

  const handleCleckSaleOrder = async (record, selectItem) => {
    console.log('RowRecordAR', record, '/', selectItem);
    const response = await getStockListById(_.get(record, 'itemId'));
    const whrp = _.get(response, 'data.data.stockList');
    setRecordList(record);

    const tempft = _.filter(selectItem, (i) => {
      return i.saleOrderItemId === _.get(record, 'saleOrderItemId');
    });
    const ftSO = tempft[0];
    const ftsoindex = _.get(ftSO, 'warehouseSeletedList');
    console.log('ftSO', ftsoindex, '/', ftSO);
    console.log('warehouseListQTY', warehouseList, '/', whrp);

    const tempsetWh = _.map(whrp, (i, index) => {
      const fineIn = _.findIndex(ftsoindex, ['warehouseId', i.warehouseId]);
      // console.log('fineIn', _.get(ftsoindex[index], 'warehouseId'), '/', i.warehouseId);
      // if (fineIn > -1) {
      //   i.qty = parseInt(i.qty) - parseInt(ftsoindex[fineIn].qty);
      // }

      _.map(ftsoindex, (m) => {
        if (_.get(m, 'warehouseId') === i.warehouseId) {
          console.log('i.warehouseId', i.warehouseId, '/', _.get(ftsoindex, '[0].warehouseId'));
          i.qty = parseInt(i.qty) - parseInt(ftsoindex[fineIn].qty);
        }
      });

      return i;
    });
    console.log('tempsetWh', tempsetWh);
    if (ftSO) {
      console.log('ftSO1');
      setWarehouseList(tempsetWh);
    } else {
      console.log('ftSO2');
      setWarehouseList(_.get(response, 'data.data.stockList'));
    }
  };

  const handleCancelAddItem = (value) => {
    //visibleVoucher
    setVisibleAddINV(false);
  };

  const handleOpenModalQuotationPending = async (row, code) => {
    try {
      const response = await getSaleOderDetail(_.get(row, 'saleOrderId'));

      setRecord({ ..._.get(response, 'data.saleOrderDetail'), code: code });

      const tempwh = _.map(_.get(response, 'data.saleOrderItemList'), (r) => ({
        ...r,
        qtyDeliver: 0,
      }));

      setSaleOrderQTY(tempwh);
    } catch (error) {}
  };

  const handleOpenModalQuotationPendingDraft = async (row, code) => {
    try {
      const response = await getSaleOderDetail(_.get(row, 'saleOrderId'));

      const tempwh = _.map(_.get(response, 'data.saleOrderItemList'), (r) => {
        let mapin = _.map(_.get(row, 'itemList'), (i) => {
          let qtyDeliver = 0;
          if (_.get(r, 'detail.itemCategoryCode') === 'service') {
            qtyDeliver = i.qtyNum || 0;
          } else {
            qtyDeliver = i.saleOrderItemId === r.saleOrderItemId ? _.sumBy(i.warehouseSeletedList, 'qty') : 0;
          }

          return {
            ...r,
            qtyDeliver: qtyDeliver,
          };
        });

        const filterMapin = _.filter(mapin, 'qtyDeliver');
      
        console.log('EEEEDOK',mapin);
        

        if (_.get(r, 'detail.itemCategoryCode') === 'service') {
          return mapin[0];
        } else {
          return {
            ...r,
            qtyDeliver: _.sumBy(mapin, 'qtyDeliver'),
          };
        }
        


      });

      console.log('tempwh', tempwh, response, row);

      setSaleOrderQTY(tempwh);
    } catch (error) {}
  };

  return (
    <InvoiceContext.Provider
      value={{
        state: {
          isOpenQuotation,
          isFetch,
          openUpload,
          attachmentData,
          typeAttachment,
          defaultAttachment,
          reportDataWaiting,
          reportDataApproved,
          reportDataRejected,
          reportDataCanceled,
          initialMemberList,
          initialTaxList,
          initialReasonList,
          initialCampaignList,
          record,
          visibleApprove,
          visibleReject,
          visibleCancel,
          visibleDelete,
          visibleWaiting,
          recordReport,
          sizeRecordReport,
          signatureUser,
          selectCustomer,
          statusList,
          signatureIssu,
          customerList,
          vehicleList,
          pageTable,
          sizeTable,
          warehouseList,
          saleOrderQTY,
          recordList,
          totalWarehouseList,
          visibleAddINV,
          defaultSaleOrder,
        },
        setState: {
          seIsOpenQuotation,
          setIsFetch,
          setOpenUpload,
          setAttachmentData,
          setTypeAttachment,
          setDefaultAttachment,
          setTabKey,
          setReportDataWaiting,
          setReportDataApproved,
          setReportDataRejected,
          setReportDataCanceled,
          setRecord,
          setSizeRecordReport,
          setSelectCustomer,
          setCustomerList,
          setCustomerTrigger,
          setPageTable,
          setSizeTable,
          setWarehouseList,
          setSaleOrderQTY,
          setRecordList,
          setTotalWarehouseList,
          setVisibleAddINV,
          setDefaultSaleOrder,
          setRecordReport,
        },
        func: {
          handleOpenModalQuotation,
          handleSaveQuotation,
          handleOpenModalUpload,
          handleCancelQuotation,
          handleOpenModalUpload,
          handleOpenApprove,
          handleSaveApprove,
          handleCancelApprove,
          handleOpenReject,
          handleSaveReject,
          handleCancelReject,
          handleOpenCancel,
          handleSaveCancel,
          handleCancelCancelQT,
          handleOpenDelete,
          handleSaveDelete,
          handleCancelDelete,
          handleOpenWaiting,
          handleSaveWaiting,
          handleCancelWaitng,
          handleDeleteAttAchment,
          handleCancelWaiting,
          refreshModalSaleOrder,
          handleExport,
          handleCustomerPopupScroll,
          handleCleckSaleOrder,
          handleCancelAddItem,
          handleOpenModalQuotationPending,
        },
      }}
    >
      {children}
    </InvoiceContext.Provider>
  );
};

export { InvoiceContext, InvoiceProvider };
