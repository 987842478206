import React, { useState, useEffect, useRef } from 'react';
import './index.css';
import { Card, Table, Dropdown, Menu, Button, Row, Col, Modal, Select, Form, Tag, Popover } from 'antd';
import { FormattedMessage } from 'react-intl';
import ReportAssetExpenseAction from './action';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import { useDebounce } from '../../../controllers/debounce';
import { successNotification, errorNotification } from '../../../components/v2/notification';
import Button01 from '../../../components/v2/button_01';
import { DownOutlined } from '@ant-design/icons';
import { numberFormatter } from '../../../component-function/fnc-inputnumber-currency';
import { PageSettings } from '../../../config/page-settings';
import ModalAsset from './modal-asset';
import { exportAssets, getListAssetExpenseReport } from '../../../controllers/resource-report/resource-report-api';

const dateFormat = 'DD/MM/YYYY';

const { Option } = Select;

const ResourceAssetExpenseReport = (props) => {
  const { form } = props;

  const intl = useIntl();

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: 70,
      fixed: 'left',
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'reportAssetExpenseColumnsAssetNo', defaultMessage: 'Asset No.' }),
      dataIndex: 'assetNo',
      key: 'assetNo',
      sorter: true,
      default: true,
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'reportAssetExpenseColumnsAssetClass', defaultMessage: 'Asset Class' }),
      dataIndex: 'assetClassName',
      key: 'assetClassName',
      sorter: true,
      default: false,
      fixed: 'left',
    },
    {
      title: intl.formatMessage({ id: 'reportAssetExpenseColumnsAssetType', defaultMessage: 'Asset Type' }),
      dataIndex: 'assetTypeName',
      key: 'assetTypeName',
      sorter: true,
      default: false,
    },
    {
      title: intl.formatMessage({ id: 'reportAssetExpenseColumnsAsset', defaultMessage: 'Asset' }),
      dataIndex: 'asset',
      key: 'asset',
      sorter: true,
      default: true,
    },
    {
      title: intl.formatMessage({ id: 'reportAssetExpenseColumnsSerialNo', defaultMessage: 'Serial No.' }),
      dataIndex: 'serialNo',
      key: 'serialNo',
      sorter: true,
      default: true,
    },

    {
      title: intl.formatMessage({ id: 'reportAssetExpenseColumnsInternalCost', defaultMessage: 'Internal Cost' }),
      dataIndex: 'internalCost',
      key: 'internalCost',
      sorter: true,
      default: true,
      align: 'right',
      render: (text, record, index) => numberFormatter(record.internalCost || 0) + ' THB',
    },

    {
      title: intl.formatMessage({ id: 'reportAssetExpenseColumnsExternalCost', defaultMessage: 'External Cost' }),
      dataIndex: 'externalCost',
      key: 'externalCost',
      sorter: true,
      default: true,
      align: 'right',
      render: (text, record, index) => numberFormatter(record.externalCost || 0) + ' THB',
    },

    {
      title: intl.formatMessage({ id: 'reportAssetExpenseColumnsTotal', defaultMessage: 'Total' }),
      dataIndex: 'total',
      key: 'total',
      sorter: true,
      default: true,
      align: 'right',
      render: (text, record, index) => numberFormatter(record.total || 0) + ' THB',
    },

    {
      title: intl.formatMessage({ id: 'reportAssetExpenseColumnsOwner', defaultMessage: 'Owner' }),
      dataIndex: 'owner',
      key: 'owner',
      sorter: true,
      default: false,
    },
  ];
  const setShowColumnArr = _.map(columns, (item) => {
    return item.dataIndex;
  });
  const setShowColumn = columns.reduce((obj, item) => {
    obj[item.dataIndex] = item.default;
    return obj;
  }, {});
  const ignoreColumn = _.map(
    _.filter(columns, (item) => {
      return item.default === false;
    }),
    (item) => {
      return item.dataIndex;
    }
  );
  const newColumns = columns.filter((col) => !ignoreColumn.includes(col.key));
  const [search, setSearch] = useState({});
  const [newDataColumns, setNewDataColumns] = useState([...newColumns]);
  const [listArrayColumns, setListArrayColumns] = useState([...setShowColumnArr]);
  const [defaultShowColumn, setDefaultShowColumn] = useState({ ...setShowColumn });
  const [textErrorSelectColumn, setTextErrorSelectColumn] = useState('');
  const [visible, setVisible] = useState(false);
  const columnMinimum = 2;
  const [sortOrder, setSortOrder] = useState('');
  const [sortField, setSortField] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState([]);
  const [refTypeList, setRefTypeList] = useState([]);
  const [summary, setSummary] = useState([]);
  const searchDebounce = useDebounce(search, 500);

  // const [itemSelect, setItemSelect] = useState();
  const [itemLoading, setItemLoading] = useState(false);
  // const [itemListView, setItemListView] = useState()

  const [visiblePopoverTable, setVisiblePopoverTable] = useState();
  const [recordPopover, setRecordPopover] = useState();
  const [offsetPopover, setOffsetPopover] = useState([0, 0]);
  const [visibleAssetModal, setVisibleAssetModal] = useState(false)
  const ref = useRef();


  const [isFetch, setIsFetch] = useState(false);

  const { getFieldDecorator } = form;

  useEffect(() => {
    console.log('search', search);
    const getListDate = async () => {
      setLoading(true);

      const body = {
        "search": _.get(search, 'asset') || undefined, 
        "assetClass": _.get(search, 'assetClass') || undefined,
        "assetTypeName": _.get(search, 'assetType') || undefined,
        "owner": _.get(search, 'owner') || undefined,
        "page": page,
        "pageSize": size,
        "orderBy": sortField,
        "order": sortOrder
      }
      const response = await getListAssetExpenseReport(body);

      setListData(_.get(response, 'data.data.list'))
      setTotal(_.get(response, 'data.data.total'))

      setRefTypeList(_.get(response, 'data.initialData'));
      setSummary(_.get(response, 'data.data.summaryList'));
      setLoading(false);
    };
    getListDate();
  }, [page, size, sortOrder, sortField, isFetch, _.get(search, 'asset')]);

  const onFilterOrder = async (value, code) => {
    setSearch({ ...search, [code]: value });
    // setPage(1);
  };

  const handleChange = (pagination, sorter, extra) => {
    let field = extra.field;
    if (extra.order) {
      if (extra.order === 'descend') {
        setSortOrder('desc');
      } else {
        setSortOrder('asc');
      }
    } else {
      setSortOrder('');
    }
    setSortField(field);
    setPage(pagination.current);
    setSize(pagination.pageSize);
  };

  const showTotal = () => {
    return (
      <span style={{ fontSize: '13px' }}>
        <FormattedMessage id="lblRowPerPage" defaultMessage="Rows per page: " />
      </span>
    );
  };

  const handleExport = async () => {
    try {
      const body = {
        "search": _.get(search, 'asset') || undefined, 
        "assetClass": _.get(search, 'assetClass') || '',
        "assetTypeName": _.get(search, 'assetType') || '',
        "owner": _.get(search, 'owner') || '',
        "page": 1,
        "pageSize": total,
        "orderBy": sortField,
        "order": sortOrder,

        viewFormat: [
          {
            colName: intl.formatMessage({ id: 'reportAssetExpenseColumnsAssetNo', defaultMessage: 'Asset No.' }),
            sequence: 1,
            colCode: 'assetNo',
          },
          {
            colName: intl.formatMessage({ id: 'reportAssetExpenseColumnsAssetClass', defaultMessage: 'Asset Class' }),
            sequence: 2,
            colCode: 'assetClassName',
          },
          {
            colName: intl.formatMessage({ id: 'reportAssetExpenseColumnsAssetType', defaultMessage: 'Asset Type' }),
            sequence: 3,
            colCode: 'assetTypeName',
          },
          {
            colName: intl.formatMessage({ id: 'reportAssetExpenseColumnsAsset', defaultMessage: 'Asset' }),
            sequence: 4,
            colCode: 'asset',
          },
          {
            colName: intl.formatMessage({ id: 'reportAssetExpenseColumnsSerialNo', defaultMessage: 'Serial No.' }),
            sequence: 5,
            colCode: 'serialNo',
          },
          {
            colName: intl.formatMessage({ id: 'reportAssetExpenseColumnsInternalCost', defaultMessage: 'Internal Cost' }),
            sequence: 6,
            colCode: 'internalCost',
          },
          {
            colName: intl.formatMessage({ id: 'reportAssetExpenseColumnsExternalCost', defaultMessage: 'External Cost' }),
            sequence: 7,
            colCode: 'externalCost',
          },
          {
            colName: intl.formatMessage({ id: 'reportAssetExpenseColumnsTotal', defaultMessage: 'Total' }),
            sequence: 8,
            colCode: 'total',
          },
          {
            colName: intl.formatMessage({ id: 'reportAssetExpenseColumnsOwner', defaultMessage: 'Owner' }),
            sequence: 9,
            colCode: 'owner',
          },
        ],
      };
      const response = await exportAssets(body);
      if (response.status === 200) {
        window.open(response.data.excelExportUrl);
        successNotification(response.data.status.message);
      } else {
        errorNotification(response.data.status.message);
      }
    } catch (error) {}
  };

  const onFind = (code) => {
    const item = _.find(summary, { code })
    return item ? item.total : 0;
  }

  const menuColumn = (
    <Menu>
      <Menu.Item>
        <Button style={{ padding: 'unset' }} ghost type="link" onClick={handleExport}>
          <FormattedMessage id="expenseBtnExport" defaultMessage="Export Excel" />
        </Button>
      </Menu.Item>
    </Menu>
  );

  const handleMouseLeave = () => {
    setVisiblePopoverTable(false);
    setOffsetPopover([0, 0]);
  };

  const handleOpenModal = async(record) => {
    setVisibleAssetModal(true)
    console.log('handleOpenModal', record)

    // const response = await getAssetExpenseReportId(record)
  }

  const handleCloseAssetModal = () => {
    setVisibleAssetModal(false)
    setRecordPopover();
  }

  const fncActionTable = (record, offsetPopover, visiblePopoverTable, ref) => {
    return (
      <div>
        <Popover
          placement="right"
          trigger="hover"
          align={{ offset: offsetPopover }}
          visible={visiblePopoverTable}
          zIndex={10000}
          content={
            <div style={{ display: 'grid' }}>
              <PageSettings.Consumer>
                {({ checkPermissionAction }) => (
                  <>
                    <Button style={{ width: 100 }} type="link" ghost onClick={() => handleOpenModal(record)}>
                      <FormattedMessage id="btnView" defaultMessage="View" />
                    </Button>
                  </>
                )}
              </PageSettings.Consumer>
            </div>
          }
        >
          <div style={{ width: 0, height: 0 }} ref={ref}></div>
        </Popover>
      </div>
    );
  };

  return (
    <div>
      <Card
        className="report-asset-expense"
        title={intl.formatMessage({ id: 'menuAssetExpenseReport', defaultMessage: 'Asset Expense Report' })}
        bodyStyle={{ padding: 'unset', height: 'calc(100vh - 170px)', overflowY: 'auto' }}
        extra={
          <Dropdown overlay={menuColumn} trigger={['click']} placement="bottomRight">
            <Button01 key="submit" type="primary" btnsize="wd_df">
              <FormattedMessage id="btnActionGroup" defaultMessage="Action" />
              <DownOutlined style={{ fontSize: '14px', paddingLeft: '22%' }} />
            </Button01>
          </Dropdown>
        }
      >
        <Row style={{ padding: '32px 24px 16px 24px' }}>
          <Col span={8} style={{ textAlign: 'center' }}>
            <div className="reportItemSalesTextHead">
              <FormattedMessage id="menuReportAssetExpenseInternalCost" defaultMessage="Internal Cost" />
            </div>
            <div style={{fontSize: '16px', color: '#1D3557'}}>{numberFormatter(onFind('internalCost'))} THB</div>
          </Col>
          <Col span={8} style={{ textAlign: 'center' }}>
            <div className="reportItemSalesTextHead">
              <FormattedMessage id="menuReportAssetExpenseExternalCost" defaultMessage="External Cost" />
            </div>
            <div style={{fontSize: '16px', color: '#1D3557'}}>{numberFormatter(onFind('externalCost'))} THB</div>
          </Col>

          <Col span={8} style={{ textAlign: 'center' }}>
            <div className="reportItemSalesTextHead">
              <FormattedMessage id="menuReportAssetExpenseTotalCost" defaultMessage="Total Cost" />
            </div>
            <div style={{fontSize: '16px', color: '#1D3557'}}>{numberFormatter(onFind('summaryCost'))} THB</div>
          </Col>
        </Row>

        <ReportAssetExpenseAction
          form={form}
          search={search}
          setSearch={setSearch}
          setPage={setPage}
          setIsFetch={setIsFetch}
          onFilterOrder={onFilterOrder}
          refTypeList={refTypeList}
          actionColumns={{
            columns,
            setShowColumn,
            newDataColumns,
            setNewDataColumns,
            setListArrayColumns,
            setDefaultShowColumn,
            listArrayColumns,
            defaultShowColumn,
            newColumns,
            textErrorSelectColumn,
            setTextErrorSelectColumn,
            visible,
            setVisible,
            columnMinimum,
          }}
        />

        <div style={{ padding: '16px 24px' }} onMouseLeave={handleMouseLeave}>
          <>
            <Table
              dataSource={listData}
              columns={newDataColumns}
              onChange={handleChange}
              loading={loading}
              scroll={{ x: true }}
              pagination={{
                total: total,
                showTotal: showTotal,
                defaultCurrent: 1,
                current: page,
                pageSizeOptions: ['10', '20', '30', '40', '50'],
                showSizeChanger: true,
                locale: { items_per_page: '' },
              }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    if (!event.target.href) {
                      const { x, y } = ref.current.getBoundingClientRect();
                      setVisiblePopoverTable(true);
                      setOffsetPopover([event.pageX - x, event.pageY - y]);
                      setRecordPopover(record);
                    }
                  },
                };
              }}
            />
            {fncActionTable(recordPopover, offsetPopover, visiblePopoverTable, ref)}
            {_.size(listData) > 0 ? (
              <div className="total-items-member">
                <span style={{ fontSize: '13px' }}>
                  <FormattedMessage id="lblTotal" defaultMessage="Total" />
                  {` ${total || 0} `}
                  <FormattedMessage id="lblitems" defaultMessage="items" />
                </span>
              </div>
            ) : null}
          </>
        </div>
      </Card>

      {visibleAssetModal && (
        <ModalAsset 
          record={recordPopover}
          visible={visibleAssetModal}
          handleClose={handleCloseAssetModal}
        />
      )}
      
    </div>
  );
};

const ResourceAssetExpenseReportForm = Form.create({
  name: 'modal_form',
  mapPropsToFields(props) {},
})(ResourceAssetExpenseReport);

export default ResourceAssetExpenseReportForm;
